let $openBtn = document.querySelector('.header__hamburger--open');
let $closeBtn = document.querySelector('.header__hamburger--close');
let $navigation = document.querySelector('.header__navbox');
let $background = document.querySelector('.header__background-shadow');

const open = () => {
    $navigation.classList.add('header__navbox--visible');
    $background.classList.add('header__background-shadow--visible');
};

const close = () => {
    $navigation.classList.remove('header__navbox--visible');
    $background.classList.remove('header__background-shadow--visible');
};

$openBtn.addEventListener('click', open);
$closeBtn.addEventListener('click', close);
$background.addEventListener('click', close);